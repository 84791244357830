import {
    FIELD_ALLOWED_INVITEE_ROLES,
    FIELD_ALLOWED_SHARED_LINK_ACCESS_LEVELS,
    FIELD_CLASSIFICATION,
    FIELD_EXTENSION,
    FIELD_DESCRIPTION,
    FIELD_ID,
    FIELD_NAME,
    FIELD_OWNED_BY,
    FIELD_PERMISSIONS,
    FIELD_SHARED_LINK,
    FIELD_SHARED_LINK_ACCESS_LEVELS_DISABLED_REASONS,
    FIELD_SHARED_LINK_FEATURES,
    FIELD_TYPE as FIELD_ITEM_TYPE,
} from '../../constants';

export const CONTENT_SHARING_ERRORS = {
    400: 'badRequestError',
    401: 'noAccessError',
    403: 'noAccessError',
    404: 'notFoundError',
    500: 'loadingError',
};

export const CONTENT_SHARING_ITEM_FIELDS = [
    FIELD_ALLOWED_INVITEE_ROLES,
    FIELD_ALLOWED_SHARED_LINK_ACCESS_LEVELS,
    FIELD_CLASSIFICATION,
    FIELD_DESCRIPTION,
    FIELD_EXTENSION,
    FIELD_ID,
    FIELD_ITEM_TYPE,
    FIELD_NAME,
    FIELD_OWNED_BY,
    FIELD_PERMISSIONS,
    FIELD_SHARED_LINK,
    FIELD_SHARED_LINK_ACCESS_LEVELS_DISABLED_REASONS,
    FIELD_SHARED_LINK_FEATURES,
];

export const CONTENT_SHARING_SHARED_LINK_UPDATE_PARAMS = {
    fields: CONTENT_SHARING_ITEM_FIELDS,
};

export const CONTENT_SHARING_VIEWS = {
    SHARED_LINK_SETTINGS: 'SHARED_LINK_SETTINGS',
    UNIFIED_SHARE_MODAL: 'UNIFIED_SHARE_MODAL',
};
